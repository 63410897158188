import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/DefaultLayout.tsx";
import { graphql } from 'gatsby';
import { Box } from '@twilio-paste/box';
import { Button } from '@twilio-paste/button';
import { useTheme, StyledBase } from '@twilio-paste/theme';
import { Text } from '@twilio-paste/text';
import Changelog from '@twilio-paste/non-modal-dialog-primitive/CHANGELOG.md';
import { useNonModalDialogPrimitiveState, NonModalDialogPrimitive, NonModalDialogDisclosurePrimitive, NonModalDialogArrowPrimitive } from '@twilio-paste/non-modal-dialog-primitive';
import { SidebarCategoryRoutes } from '../../../constants';
import { Callout, CalloutTitle, CalloutText } from '../../../components/callout';
import { defaultExample, rightExample, gutterExample, styledExample } from '../../../component-examples/NonModalDialogPrimitiveExamples';
export const pageQuery = graphql`
  {
    allPastePrimitive(filter: {name: {eq: "@twilio-paste/non-modal-dialog-primitive"}}) {
      edges {
        node {
          name
          description
          status
          version
        }
      }
    }
    mdx(fields: {slug: {eq: "/primitives/non-modal-dialog-primitive/"}}) {
      fileAbsolutePath
      frontmatter {
        slug
        title
      }
      headings {
        depth
        value
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ComponentHeader = makeShortcode("ComponentHeader");
const PageAside = makeShortcode("PageAside");
const LivePreview = makeShortcode("LivePreview");
const ChangelogRevealer = makeShortcode("ChangelogRevealer");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <ComponentHeader name="Non-Modal Dialog Primitive" categoryRoute={SidebarCategoryRoutes.PRIMITIVES} githubUrl="https://github.com/twilio-labs/paste/tree/main/packages/paste-core/primitives/non-modal-dialog" storybookUrl="/?path=/story/primitives-non-modal-dialog--simple-non-modal-dialog" data={props.data.allPastePrimitive.edges} mdxType="ComponentHeader" />
    <hr></hr>
    <contentwrapper>
      <PageAside data={props.data.mdx} mdxType="PageAside" />
      <content>
        <h2>{`Guidelines`}</h2>
        <h3>{`About Non-Modal Dialog Primitive`}</h3>
        <p>{`The non-modal dialog primitive is an unstyled functional version of a non-modal dialog. Our `}<a parentName="p" {...{
            "href": "/components/popover"
          }}>{`Popover`}</a>{` is built on top of this primitive.`}</p>
        <p>{`The purpose of providing these unstyled primitives is to cater for instances when the styled Popover provided by Paste doesn’t meet the requirements needed to solve a unique or individual customer problem. At that point you are welcome to fall back to this functional primitive to roll your own styled non-modal dialog while still providing a functional and accessible experience to your customers. However, we strongly recommend reviewing your design proposal with the Design Systems team before doing so.`}</p>
        <p>{`This primitive should be used to compose all custom non-modal dialogs or popoovers to ensure accessibility and upgrade paths.`}</p>
        <Callout variant="warning" mdxType="Callout">
  <CalloutTitle as="h3" mdxType="CalloutTitle">Before you roll your own non-modal dialogs...</CalloutTitle>
  <CalloutText mdxType="CalloutText">
    We strongly suggest that all components built on top of this primitive get reviewed by the Design Systems team and
    go through the UX Review process to ensure an excellent experience for our customers.
  </CalloutText>
        </Callout>
        <h2>{`Examples`}</h2>
        <h3>{`Basic Non-Modal Dialog`}</h3>
        <LivePreview scope={{
          useNonModalDialogPrimitiveState,
          NonModalDialogPrimitive,
          NonModalDialogDisclosurePrimitive,
          NonModalDialogArrowPrimitive
        }} noInline mdxType="LivePreview">
  {defaultExample}
        </LivePreview>
        <h3>{`Right Non-Modal Dialog`}</h3>
        <LivePreview scope={{
          useNonModalDialogPrimitiveState,
          NonModalDialogPrimitive,
          NonModalDialogDisclosurePrimitive,
          NonModalDialogArrowPrimitive
        }} noInline mdxType="LivePreview">
  {rightExample}
        </LivePreview>
        <h3>{`Non-Modal Dialog without Gutter`}</h3>
        <LivePreview scope={{
          useNonModalDialogPrimitiveState,
          NonModalDialogPrimitive,
          NonModalDialogDisclosurePrimitive
        }} noInline mdxType="LivePreview">
  {gutterExample}
        </LivePreview>
        <hr></hr>
        <h2>{`Styling a Custom Non-Modal Dialog`}</h2>
        <p>{`The Non-Modal Dialog primitive can be styled using Paste components and tokens.`}</p>
        <p>{`By using the `}<inlineCode parentName="p">{`as`}</inlineCode>{` prop, we can change the underlying element and combine it with another component. In the
example below we're combiningthe `}<inlineCode parentName="p">{`NonModalDialogDisclosurePrimitive`}</inlineCode>{` with the Button component.`}</p>
        <p>{`We're also using the `}<inlineCode parentName="p">{`as`}</inlineCode>{` prop on the `}<inlineCode parentName="p">{`NonModalDialogPrimitive`}</inlineCode>{` to create a styled `}<inlineCode parentName="p">{`Box`}</inlineCode>{` with Paste
token background color, border radius, and spacing values.`}</p>
        <p>{`Because the non-modal dialog lives outside of the main `}<inlineCode parentName="p">{`body`}</inlineCode>{` tag, we need to provide the base Paste styles using
`}<inlineCode parentName="p">{`StyledBase`}</inlineCode>{` from our theme package. This gives the `}<inlineCode parentName="p">{`Text`}</inlineCode>{` primitive the appropriate font stack and sizing.`}</p>
        <LivePreview scope={{
          useTheme,
          useNonModalDialogPrimitiveState,
          NonModalDialogPrimitive,
          NonModalDialogDisclosurePrimitive,
          NonModalDialogArrowPrimitive,
          Box,
          Button,
          StyledBase,
          Text
        }} noInline mdxType="LivePreview">
  {styledExample}
        </LivePreview>
        <hr></hr>
        <h2>{`Usage Guide`}</h2>
        <p>{`This package is a wrapper around the `}<a parentName="p" {...{
            "href": "https://reakit.io/docs/popover/"
          }}>{`Reakit Popover`}</a>{`. If you’re wondering why we wrapped that package into our own, we reasoned that it would be best for our consumers’ developer experience. For example:`}</p>
        <ul>
          <li parentName="ul">{`If we want to migrate the underlying nuts and bolts in the future, Twilio products that depend on this primitive would need to replace all occurrences of `}<inlineCode parentName="li">{`import … from ‘x-package’`}</inlineCode>{` to `}<inlineCode parentName="li">{`import … from ‘@some-new/package’`}</inlineCode>{`. By wrapping it in `}<inlineCode parentName="li">{`@twilio-paste/x-primitive`}</inlineCode>{`, this refactor can be avoided. The only change would be a version bump in the ‘package.json\` file for the primitive.`}</li>
          <li parentName="ul">{`We can more strictly enforce semver and backwards compatibility than some of our dependencies.`}</li>
          <li parentName="ul">{`We can control when to provide an update and which versions we allow, to help reduce potential bugs our consumers may face.`}</li>
          <li parentName="ul">{`We can control which APIs we expose. For example, we may chose to enable or disable usage of certain undocumented APIs.`}</li>
        </ul>
        <h3>{`API`}</h3>
        <h4>{`Installation`}</h4>
        <pre><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`yarn add @twilio-paste/non-modal-dialog-primitive - or - yarn add @twilio-paste/core
`}</code></pre>
        <h4>{`Props`}</h4>
        <p>{`This props list is a scoped version of the properties available from the `}<a parentName="p" {...{
            "href": "https://reakit.io/docs/popover/"
          }}>{`Reakit Popover`}</a>{` package.`}</p>
        <h5>{`useNonModalDialogPrimitiveState`}</h5>
        <h5><inlineCode parentName="h5">{`baseId?: string`}</inlineCode></h5>
        <p>{`Sets the ID that will serve as a base for all the items IDs.`}</p>
        <h5><inlineCode parentName="h5">{`gutter?: string`}</inlineCode></h5>
        <p>{`Sets the offset between the reference and the popover on the main axis.`}</p>
        <h5><inlineCode parentName="h5">{`placement?: "auto-start" | "auto" | "auto-end" | "top-start...`}</inlineCode></h5>
        <p>{`Sets the placement of popover in relation to the `}<inlineCode parentName="p">{`NonModalDialogDisclosurePrimitive`}</inlineCode>{`. Available options include:`}</p>
        <ul>
          <li parentName="ul">{`auto-start`}</li>
          <li parentName="ul">{`auto-end`}</li>
          <li parentName="ul">{`auto`}</li>
          <li parentName="ul">{`top-start`}</li>
          <li parentName="ul">{`top-end`}</li>
          <li parentName="ul">{`top`}</li>
          <li parentName="ul">{`bottom-start`}</li>
          <li parentName="ul">{`bottom-end`}</li>
          <li parentName="ul">{`bottom`}</li>
          <li parentName="ul">{`right-start`}</li>
          <li parentName="ul">{`right-end`}</li>
          <li parentName="ul">{`right`}</li>
          <li parentName="ul">{`left-start`}</li>
          <li parentName="ul">{`left-end`}</li>
          <li parentName="ul">{`left`}</li>
        </ul>
        <h5><inlineCode parentName="h5">{`visible?: boolean`}</inlineCode></h5>
        <p>{`Whether the dialog is visible or not.`}</p>
        <h5><inlineCode parentName="h5">{`animated?: number | boolean`}</inlineCode></h5>
        <p>{`If true, animating will be set to true when visible is updated. It'll wait for stopAnimation to be called or a CSS transition ends. If animated is set to a number, stopAnimation will be called only after the same number of milliseconds have passed.`}</p>
        <h5><inlineCode parentName="h5">{`modal?: boolean`}</inlineCode></h5>
        <p>{`Sets the modal state.`}</p>
        <ul>
          <li parentName="ul">{`Non-modal: preventBodyScroll doesn't work and focus is free.`}</li>
          <li parentName="ul">{`Modal: preventBodyScroll is automatically enabled, focus is trapped within the dialog and the dialog is rendered within a Portal.`}</li>
        </ul>
        <h5>{`NonModalDialogPrimitive`}</h5>
        <h5><inlineCode parentName="h5">{`hideOnEsc?: boolean | undefined`}</inlineCode></h5>
        <p>{`If enabled, the user can hide the dialog by pressing Escape.`}</p>
        <h5><inlineCode parentName="h5">{`hideOnClickOutside?: boolean | undefined`}</inlineCode></h5>
        <p>{`If enabled, the user can hide the dialog by clicking outside it.`}</p>
        <h5>{`NonModalDialogArrowPrimitive`}</h5>
        <h5><inlineCode parentName="h5">{`size?: string | number | undefined`}</inlineCode></h5>
        <p>{`The size of the arrow`}</p>
        <h5><inlineCode parentName="h5">{`fill?: string`}</inlineCode></h5>
        <p>{`Sets the fill color of the arrow svg path`}</p>
        <h5><inlineCode parentName="h5">{`stroke?: string`}</inlineCode></h5>
        <p>{`Sets the stroke color of the arrow svg path`}</p>
        <h5>{`NonModalDialogDisclosurePrimitive`}</h5>
        <h5><inlineCode parentName="h5">{`disabled boolean | undefined`}</inlineCode></h5>
        <p>{`Sets the disclosure to disabled.`}</p>
        <h5><inlineCode parentName="h5">{`focusable boolean | undefined`}</inlineCode></h5>
        <p>{`Sets the disclosure to be focusable or not.`}</p>
        <ChangelogRevealer mdxType="ChangelogRevealer">
  <Changelog mdxType="Changelog" />
        </ChangelogRevealer>
      </content>
    </contentwrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      